import React from "react";
import {
  matchRoutes,
  Navigate,
  RouteObject,
  useLocation,
} from "react-router-dom";

import { Footer } from "../components/Footer/Footer";
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from "../router/routes";
import { useAppSelector } from "../store/hooks";
import { ViewType } from "../store/slice";
import { paths } from "./../constants/paths";
import { LayoutProps } from "./Layout.interface";

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const token = useAppSelector((store) => store.auth.token);
  const viewType = useAppSelector((store) => store.global.viewType);

  const isAuthenticated = !!token;

  const authenticated_route = Boolean(
    matchRoutes(AUTHENTICATED_ROUTES as RouteObject[], pathname)?.length
  );
  const unauthenticated_route = Boolean(
    matchRoutes(UNAUTHENTICATED_ROUTES as RouteObject[], pathname)?.length
  );

  if (!isAuthenticated && authenticated_route)
    return <Navigate to={paths.LOGIN} />;

  if (isAuthenticated && unauthenticated_route)
    return <Navigate to={paths.HOME} replace />;

  return (
    <div>
      <div>{children(isAuthenticated)}</div>
      {viewType === ViewType.sm ? <Footer /> : null}
    </div>
  );
};
