export const SvgHome = ({ active }: { active: boolean }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.3L9.1 1L17.2 7.3V17.2C17.2 17.6774 17.0104 18.1352 16.6728 18.4728C16.3352 18.8104 15.8774 19 15.4 19H2.8C2.32261 19 1.86477 18.8104 1.52721 18.4728C1.18964 18.1352 1 17.6774 1 17.2V7.3Z"
        stroke={active ? "#0E4A7C" : "#6C6C6C"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.39999 19V10H11.8V19"
        stroke={active ? "#0E4A7C" : "#6C6C6C"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SvgInfo = ({ active }: { active: boolean }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_133_319)">
        <path
          d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520204 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0V0ZM9 16.5C7.51664 16.5 6.0666 16.0601 4.83323 15.236C3.59986 14.4119 2.63856 13.2406 2.07091 11.8701C1.50325 10.4997 1.35473 8.99168 1.64411 7.53682C1.9335 6.08197 2.64781 4.74559 3.6967 3.6967C4.7456 2.64781 6.08197 1.9335 7.53683 1.64411C8.99168 1.35472 10.4997 1.50325 11.8701 2.0709C13.2406 2.63856 14.4119 3.59985 15.236 4.83322C16.0601 6.06659 16.5 7.51664 16.5 9C16.4978 10.9885 15.7069 12.8948 14.3009 14.3009C12.8948 15.7069 10.9885 16.4978 9 16.5Z"
          fill={active ? "#0E4A7C" : "#6C6C6C"}
        />
        <path
          d="M8.99988 7.50024H8.24988C8.05096 7.50024 7.8602 7.57926 7.71955 7.71991C7.5789 7.86057 7.49988 8.05133 7.49988 8.25024C7.49988 8.44916 7.5789 8.63992 7.71955 8.78057C7.8602 8.92123 8.05096 9.00024 8.24988 9.00024H8.99988V13.5002C8.99988 13.6992 9.07889 13.8899 9.21954 14.0306C9.3602 14.1712 9.55096 14.2502 9.74987 14.2502C9.94879 14.2502 10.1396 14.1712 10.2802 14.0306C10.4209 13.8899 10.4999 13.6992 10.4999 13.5002V9.00024C10.4999 8.60242 10.3418 8.22089 10.0605 7.93958C9.77923 7.65828 9.3977 7.50024 8.99988 7.50024Z"
          fill={active ? "#0E4A7C" : "#6C6C6C"}
        />
        <path
          d="M9 5.99976C9.62132 5.99976 10.125 5.49608 10.125 4.87476C10.125 4.25344 9.62132 3.74976 9 3.74976C8.37868 3.74976 7.875 4.25344 7.875 4.87476C7.875 5.49608 8.37868 5.99976 9 5.99976Z"
          fill={active ? "#0E4A7C" : "#6C6C6C"}
        />
      </g>
      <defs>
        <clipPath id="clip0_133_319">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SvgGroup = ({ active }: { active: boolean }) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16.75C1 16.1533 1.23705 15.581 1.65901 15.159C2.08097 14.7371 2.65326 14.5 3.25 14.5H15.4"
        stroke={active ? "#0E4A7C" : "#6C6C6C"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 1H15.4V19H3.25C2.65326 19 2.08097 18.7629 1.65901 18.341C1.23705 17.919 1 17.3467 1 16.75V3.25C1 2.65326 1.23705 2.08097 1.65901 1.65901C2.08097 1.23705 2.65326 1 3.25 1V1Z"
        stroke={active ? "#0E4A7C" : "#6C6C6C"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SvgUser = ({ active }: { active: boolean }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 20.9999V18.7777C19 17.599 18.5259 16.4685 17.682 15.635C16.8381 14.8015 15.6935 14.3333 14.5 14.3333H5.5C4.30653 14.3333 3.16193 14.8015 2.31802 15.635C1.47411 16.4685 1 17.599 1 18.7777V20.9999"
        stroke={active ? "#0E4A7C" : "#6C6C6C"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.88889C12.4853 9.88889 14.5 7.89904 14.5 5.44444C14.5 2.98985 12.4853 1 10 1C7.51472 1 5.5 2.98985 5.5 5.44444C5.5 7.89904 7.51472 9.88889 10 9.88889Z"
        stroke={active ? "#0E4A7C" : "#6C6C6C"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BackSvg = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9998 4.99994H2.99982L6.29282 1.70694L4.87882 0.292938L0.585817 4.58594C0.210875 4.96099 0.000244141 5.46961 0.000244141 5.99994C0.000244141 6.53027 0.210875 7.03888 0.585817 7.41394L4.87882 11.7069L6.29282 10.2929L2.99982 6.99994H12.9998V4.99994Z"
        fill="#0E4A7C"
      />
    </svg>
  );
};
