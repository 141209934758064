import styled from "styled-components";

export const StyledAbout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
`;

export const ContentBox = styled.div`
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 800px;
`;

export const Content = styled.div`
  font-size: 0.857rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding: 1rem;
`;

export const BoxFooterTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(14, 74, 124, 0.9);
  color: white;
  padding: 0.8rem;
  font-size: 0.8rem;
  span {
    margin-left: 0.5rem;
  }
`;

export const BoxFooter = styled.div`
  background-color: ${({ theme }) => theme["@primary-color"]};
  border-radius: 0 0 16px 16px;
  padding: 1rem;
  font-size: 0.8rem;
`;

export const ContactInfo = styled.div`
  font-size: 0.8rem;
  color: #fff;
`;
