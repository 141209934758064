import { RouteObject } from "react-router-dom";
import { About } from "../pages/About/About";
import { Cart } from "../pages/Cart/Cart";
import { Home } from "../pages/Home/Home";
import { Login } from "../pages/Login/Login";
import { MyProfile } from "../pages/MyProfile/MyProfile";
import { OrderSuccess } from "../pages/OrderSuccess/OrderSuccess";
import { Signup } from "../pages/Signup/Signup";
import { ViewOrder } from "../pages/ViewOrder/ViewOrder";
import { ViewOrders } from "../pages/ViewOrders/ViewOrders";
import { paths } from "./../constants/paths";

export interface RoutePram extends Record<string, string | undefined> {}

export interface PathRoute extends Omit<RouteObject, "children" | "element"> {
  element?: JSX.Element;
  children?: PathRoute[];
  render?: boolean;
}

export const OPEN_ROUTES: PathRoute[] = [
  {
    path: paths.HOME,
    element: <Home />,
  },
  {
    path: paths.ABOUT,
    element: <About />,
  },
  {
    path: paths.CART,
    element: <Cart />,
  },
  {
    path: paths.ORDER_SUCCESS,
    element: <OrderSuccess />,
  },
  {
    path: paths.LOGIN,
    element: <Login />,
  },
  {
    path: paths.SIGNUP,
    element: <Signup />,
  },
  {
    path: paths.PROFILE,
    element: <MyProfile />,
  },

  {
    path: paths.ORDER_VIEW,
    element: <ViewOrders />,
  },
  {
    path: paths.ORDER_VIEWW,
    element: <ViewOrder />,
  },
];

export const AUTHENTICATED_ROUTES: PathRoute[] = [
  {
    path: paths.PROFILE,
    element: <MyProfile />,
  },

  {
    path: paths.ORDER_VIEW,
    element: <ViewOrders />,
  },
  {
    path: paths.ORDER_VIEWW,
    element: <ViewOrder />,
  },
];

export const UNAUTHENTICATED_ROUTES: PathRoute[] = [
  {
    path: paths.LOGIN,
    element: <Login />,
  },
  {
    path: paths.SIGNUP,
    element: <Signup />,
  },
];
