import axios, { AxiosResponse } from "axios"
import { ClientPaymentToken, PaymentData } from "./payment.interfaces"

const API_BASE_URL = process.env.REACT_APP_APIURL; 

const getClientBrainTreeToken = async () => {
    try {
        const response = await axios.get<ClientPaymentToken>(`${API_BASE_URL}/payment/client-token`)
        return response.data
    } catch (err) { 
        console.log(err)
    }
}

const makePayment = async (paymentData: PaymentData) => {
   return await axios.post<AxiosResponse<any>>(`${API_BASE_URL}/payment/checkout`,paymentData)
}

export {getClientBrainTreeToken, makePayment}
