import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CategoriesList } from "../../components/CategoriesList/CategoriesList";
import { Container } from "../../components/Container/Container";
import { Header } from "../../components/Header/Header";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import {
  ProductFetch,
  fetchCategories,
  fetchProducts,
  searchProducts
} from "../../services/Product";
import { useAppSelector } from "../../store/hooks";
import {
  appendProducts,
  setCategories,
  setProducts,
} from "./../../store/slice/global";
import { HomeProps } from "./Home.interface";
import {
  Button,
  ButtonContainer,
  StyledHome,
  StyledInputSearch,
} from "./Home.styled";

const initParams: ProductFetch = {
  categoryId: "0",
  page: "1",
  limit: "10",
  search: "",
};

export const Home: React.FC<HomeProps> = () => {
  const [productsParams, setProductparams] = useState(initParams);
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const dispatch = useDispatch();
  const categories = useAppSelector((state) => state.global.categories);
  const products = useAppSelector((state) => state.global.products);
  const [selectedCategoryId, setSelectedCategoryId] = useState("0");


  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        setCategoriesLoading(true);
        const categoriesData = await fetchCategories();
        dispatch(setCategories(categoriesData));
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchCategoriesData();
  }, [dispatch]);

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        setIsLoading(true);

        const productsData = await (productsParams.search
          ? searchProducts(productsParams)
          : fetchProducts(productsParams));

        if (productsParams.page === "1") {
          // Set products on init and categoryId change
          dispatch(setProducts(productsData));
        } else {
          // Append products on load more
          dispatch(appendProducts(productsData));
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductsData();
  }, [productsParams, dispatch]);

  const handleCategoryClick = (categoryId: string) => {
    setSelectedCategoryId(categoryId);
    setProductparams({ ...initParams, search: "", categoryId });
  };

  const handleSearchChange = (event: any) => {
    if (event.key === "Enter") {
      setSelectedCategoryId("0");
      setProductparams({ ...initParams, search: event.target.value });
    }
  };

  const fetchMoreProds = async () => {
    if (!products.hasMore) return;

    const nextPage = (parseInt(productsParams.page) + 1).toString();
    const newProductsParams = {
      ...productsParams,
      categoryId: selectedCategoryId,
      page: nextPage,
    };

    setProductparams(newProductsParams);
  };

  return (
    <>
      <Header />
      <StyledHome>
        <Container>
          <CategoriesList
            categories={categories.categories}
            onCategoryClick={handleCategoryClick}
            selectedCategoryId={selectedCategoryId}
          />
          <StyledInputSearch
            placeholder="Search products..."
            onKeyDown={handleSearchChange}
            prefix={
              <SearchOutlined
                style={{
                  color: "#a9a9a9",
                  marginRight: 12,
                  marginLeft: 8,
                  fontSize: 14,
                }}
              />
            }
          />
          <ProductsList products={products.products} isLoading={isLoading} />
          <ButtonContainer>
            {products?.hasMore ? (
              <Button
                loading={isLoading}
                onClick={fetchMoreProds}
                disabled={isLoading}
              >
                {isLoading ? "loading" : "Load more"}
              </Button>
            ) : null}
          </ButtonContainer>
        </Container>
      </StyledHome>
    </>
  );
};
