import styled from "styled-components";

export const MyProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 1rem;
`;

export const MyProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  /* margin: auto; */
`;

export const MyProfileButton = styled.button`
  max-width: 400px;
  background-color: ${({ theme }) => theme["@primary-color"]};
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.8rem 1rem;
  border-radius: 4px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  width: 100%;

  &:hover:not(&:hover) {
    background-color: #40a9ff;
  }

  &:disabled {
    background-color: ${({ theme }) => theme["@primary-light"]};
    cursor: not-allowed;
  }
`;
