import { rtkApi } from "../rtk";
import { GetProduct, ProductFetch } from "./product.interfaces";

const API_BASE_URL = process.env.REACT_APP_APIURL; 

export const productApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<GetProduct, ProductFetch>({
      query: (data) => ({
        url: `/product/by-category/${data.categoryId}/${data.page}/${data.limit}/${data.search}`,
        method: "get",
      }),
    }),
  }),
});

export const { useGetProductsQuery } = productApi;

export const fetchProducts = async (params: ProductFetch) => {
  const { categoryId, page, limit } = params;
  const response = await fetch(
    `${API_BASE_URL}/product/by-category/${categoryId}/${page}/${limit}`
  );
  const data = await response.json();
  return data;
};

export const searchProducts = async (params: ProductFetch) => {
  const { page, limit, search } = params;
  const response = await fetch(
    `${API_BASE_URL}/product/get/${page}/${limit}/${search}`
  );
  const data = await response.json();
  return data;
};

export const fetchCategories = async () => {
  const response = await fetch(`${API_BASE_URL}/category/v2`);
  const data = await response.json();
  return data;
};
