import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Cart, GlobalStore, ViewType } from "./Global.interface";
import { GetCategory } from "../../../services/Category";
import { GetProduct } from "../../../services/Product";

export const initCart = {
  items: [],
  type: "delivery",
  subTotal: 0,
  additionalNote: "",
  email: ""
} as Cart;

const initialState: GlobalStore = {
  viewType: ViewType.sm,
  categories: {} as GetCategory,
  products: {} as GetProduct,
  cart: initCart,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setViewType: (state, action: PayloadAction<ViewType>) => {
      state.viewType = action.payload;
    },
    setCategories: (state, action: PayloadAction<GetCategory>) => {
      state.categories = action.payload;
    },
    setProducts: (state, action: PayloadAction<GetProduct>) => {
      state.products = action.payload;
    },
    setCart: (state, action: PayloadAction<Cart>) => {
      state.cart = action.payload;
    },
    appendProducts: (state, action: PayloadAction<GetProduct>) => {
      state.products.products = [
        ...state.products.products,
        ...action.payload.products,
      ];
      state.products.hasMore = action.payload.hasMore;
    },
  },
});

export const {
  setCategories,
  setProducts,
  setCart,
  appendProducts,
  setViewType,
} = globalSlice.actions;
export default globalSlice.reducer;
