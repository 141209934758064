import { rtkApi } from "../rtk";
import {
  User,
  LoginCredentials,
  RegisterData,
  UserUpdateData,
  UserUpdatePassword,
  TokenObj,
} from "../../store/slice/auth";
import { Cart } from "../../store/slice";
import { AuthResponse } from "./Auth.interfaces";

export const authApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, LoginCredentials>({
      query: (credentials) => ({
        url: "/user/login",
        method: "POST",
        data: credentials,
      }),
    }),
    register: builder.mutation<AuthResponse, RegisterData>({
      query: (data) => ({
        url: "/user/signup",
        method: "POST",
        data,
      }),
    }),
    logout: builder.mutation<null,null>({
      query: () => ({
        url: "/user/logout",
        method: "POST"
      }),
    }),
    refreshToken: builder.mutation<TokenObj, null>({
      query: () => ({
        url: "/user/refreshToken",
        method: "POST"
      })
    }),
    upadteUser: builder.mutation<User, UserUpdateData>({
      query: (data) => ({
        url: "/user/update",
        method: "PUT",
        data,
      }),
    }),
    upadteUserPassword: builder.mutation<User, UserUpdatePassword>({
      query: (data) => ({
        url: "/user/update",
        method: "PUT",
        data,
      }),
    }),
    addOrder: builder.mutation<null, Cart>({
      query: (data) => ({
        url: "/user/add-order",
        method: "POST",
        data,
      }),
    }),
    getUser: builder.query<User, null>({
      query: () => ({
        url: `/user/get-by-token`,
        method: "GET",
      }),
    }),
    viewOrders: builder.query<any, void>({
      query: () => ({
        url: `/user/get-past-orders/1/100`,
        method: "GET",
      }),
    }),
    viewOrder: builder.query<any, string>({
      query: (orderId) => ({
        url: `/user/get-order-item-by-id/${orderId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLogoutMutation,
  useRefreshTokenMutation,
  useLoginMutation,
  useRegisterMutation,
  useUpadteUserMutation,
  useUpadteUserPasswordMutation,
  useAddOrderMutation,
  useGetUserQuery,
  useViewOrdersQuery,
  useViewOrderQuery
} = authApi;
