import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { paths } from "../constants/paths";

const useAuthRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useAppSelector((store) => store.auth.token);
  const isAuthenticated = !!token;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(paths.LOGIN);
    }
  }, [isAuthenticated, navigate, location]);
};

export default useAuthRedirect;
