import { Button, Card, Row } from "antd";
import styled from "styled-components";

export const StyledRow = styled(Row)`
  margin-top: 16px;

  /* .prod-price {
    font-size: 18px;
    color: ${({theme}) => theme["@primary-color"]}
  } */
`;

export const StyledCard = styled(Card)`
  position: relative;
`;

export const StyledAddButton = styled(Button)`
  position: absolute;
  background-color: ${({ theme }) => theme["@primary-color"]};
  top: 10px;
  right: 10px;
  z-index: 1;
  border-radius: 16px;
`;
