import {
  LoginOutlined,
  ShoppingCartOutlined
} from "@ant-design/icons";
import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";
import { useAppSelector } from "../../store/hooks";
import { BackSvg } from "../AppSvgs";
import logoMain from "./../../assets/imgs/logoMain.png";
import { Container } from "./../Container/Container";
import { HeaderProps } from "./Header.interface";
import {
  HeaderWrapper,
  MobileMenu,
  PlainHeader,
  ShoppingDot
} from "./Header.styled";

const Header: React.FC<HeaderProps> = ({ heading }) => {
  const user = useAppSelector((state) => state.auth.user);
  const cart = useAppSelector((state) => state.global.cart);

  if (heading) {
    return (
      <PlainHeader>
        {/* <Container> */}
        <Link to={paths.HOME}>
          <BackSvg />
        </Link>
        <h3>{heading}</h3>
        <div></div>
        {/* </Container> */}
      </PlainHeader>
    );
  }

  return (
    <Container>
      <HeaderWrapper>
        <Link to={paths.HOME}>
          <Image preview={false} src={logoMain} alt="Logo" width={170} />
        </Link>
        {/* <DesktopMenu>
            <ActionButtons>
              <Link to={paths.ABOUT}>
                <Button>About</Button>
              </Link>
              {user ? (
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  className="cursor-pointer"
                >
                  <Image
                    src={userLogo}
                    preview={false}
                    alt={user.name}
                    width={35}
                  />
                </Dropdown>
              ) : (
                <Link to={paths.LOGIN}>
                  <Button>Login</Button>
                </Link>
              )}
              {!user?.isAdmin ? (
                <Link to={paths.CART}>
                  <Button>
                    <ShoppingCartOutlined
                      style={{ color: "#0E4A7C", fontSize: 20 }}
                    />
                    {cart.items?.length ? <ShoppingDot /> : null}
                  </Button>
                </Link>
              ) : null}
            </ActionButtons>
          </DesktopMenu> */}
        <MobileMenu>
            {!user && <Link to={paths.LOGIN} style={{ position: "relative" }}>
              <LoginOutlined style={{
                color: "#0E4A7C",
                fontSize: 22,
              }} />
            </Link>}
            <Link to={paths.CART} style={{ position: "relative" }}>
              <ShoppingCartOutlined
                style={{
                  color: "#0E4A7C",
                  fontSize: 22,
                }}

              />
              {cart.items?.length ? (
                <ShoppingDot>{cart.items.length || 0}</ShoppingDot>
              ) : null}
            </Link>

        </MobileMenu>
      </HeaderWrapper>
    </Container>
  )
}
export { Header };
