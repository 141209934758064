import styled from "styled-components";

export const StyledTable = styled.div`
  .antd-table {
    background-color: white;
    margin-top: 1rem;

    .ant-table-thead > tr > th {
      display: none;
    }

    .ant-table-container {
      background-color: white;
    }

    .ant-table-cell {
      padding: 0.5rem;
      padding-bottom: 0.5rem;
    }

    p {
      margin: 0;
    }

    .name {
      font-size: 0.875rem;
      line-height: 19px;
      color: black;
      letter-spacing: 0.04em;
    }

    .desc {
      font-size: 0.8rem;
    }
  }
`;

export const QuantityButton = styled.span`
  background-color: ${({ theme }) => theme["@primary-color"]};
  border-color: ${({ theme }) => theme["@primary-color"]};
  width: 18px;
  height: 18px;
  font-size: 14px;
  border-radius: 50%;
  color: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const QuantityInput = styled.input`
  border-width: 0px;
  width: 20px;
  text-align: center;
  border-radius: 4px;
  /* margin: 0 0.5rem; */

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ResponsiveRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const RowDivider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8rem;

  .price {
    color: ${({ theme }) => theme["@primary-color"]};
    font-size: 0.875rem;
    font-weight: 700;
  }

  .quantity {
    .ctrls {
      display: flex;
      justify-content: space-between;
    }
    .cart-btn {
      background-color: ${({ theme }) => theme["@primary-color"]};
      border-color: ${({ theme }) => theme["@primary-color"]};
      color: white;
      cursor: pointer;
      border-radius: 0.3rem;
      box-shadow: none;
      outline: none;
      padding: 0.1rem 1rem;
      margin-top: 0.7rem;
      text-align: center;
      span {
        font-size: 10px;
      }
    }
  }
`;

export const ProductInfo = styled.div`
  text-align: left;
  position: relative;
`;
