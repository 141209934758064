import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { Axios, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { RootState } from "../store";
import { TokenObj, reset, setToken, setTokenExpiresIn } from "../store/slice";
import toast from "react-hot-toast";

export const axiosClient = new Axios({
  baseURL: process.env.REACT_APP_APIURL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

axiosClient.interceptors.request.use((request: any) => {
  const token = localStorage.getItem("token");

  if (token)
    request.headers = { ...request.headers, Authorization: `Bearer ${token}` };

  return request;
});

axiosClient.interceptors.response.use((response: AxiosResponse<Response>) => {
  //
  // if response in data is string convert it to JSON
  //
  if (response.data && typeof response.data === "string")
    response.data = JSON.parse(response.data);

  //
  // if error got into response throw it into error
  //
  if (!response.status.toString().startsWith("2")) throw response;

  return response;
});

type extraoptions = Partial<
  Omit<AxiosRequestConfig, "url" | "method" | "data" | "params">
>;

type Query = {
  url: string;
  method?: Method;
  formData?: boolean;
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
};

type BaseQuery = BaseQueryFn<
  Query,
  Response,
  Response,
  extraoptions | ((value: Query) => extraoptions)
>;

export const axiosBaseQuery =
  (): BaseQuery => async (query, basequery, extraOptions) => {
    const state = basequery.getState() as RootState;
    const dispatch = basequery.dispatch;
    let {
      url,
      method = "get",
      data = undefined,
      params = undefined,
      formData,
    } = query;

    if (formData) {
      const formdata = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        formdata.append(key, value as string | Blob);
      });

      data = formdata;
    } else {
      data = JSON.stringify(data);
    }

    const result = await axiosClient
      .request({
        url,
        method,
        data,
        params,
        ...(typeof extraOptions === "function"
          ? extraOptions(query)
          : extraOptions),
      })
      .catch(async (error) => {
        const tokenExpiresIn = state?.auth?.tokenExpiresIn
        const millisecondInHour = 60 * 60 * 1000
        if (error?.status === 401 && tokenExpiresIn && tokenExpiresIn <= Date.now() + millisecondInHour) {
          axiosClient.request<TokenObj>({
            url: '/user/refreshToken',
            method: 'POST',
          }).then((res) => {
            dispatch(setToken(res.data))
            dispatch(setTokenExpiresIn(res.data))
          }).catch((err) => { 
            toast.error('You need to login again ')
            dispatch(reset())
            console.error('error while getting new token')
          })
        } 
          throw error?.data?.message ?? error?.message ?? "Some Error Occured";
      });

    return { data: result.data };
  };
