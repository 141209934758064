import React from "react";
import { Container } from "../../components/Container/Container";
import {
  StyledAbout,
  ContentBox,
  Content,
  Heading,
  Button,
} from "./OrderSuccess.styled";
import { Header } from "../../components/Header/Header";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";
import { Image } from "antd";
import CART_IMG from "./../../assets/imgs/successcart.png";

const OrderSuccess: React.FC = () => {
  return (
    <>
      <Header heading={"Order Success"} />
      <Container>
        <StyledAbout>
          <ContentBox>
            <Content>
              <Image src={CART_IMG} width={220} preview={false} />
              <Heading>Thanks for your order</Heading>
              <Link to={paths.HOME}>
                <Button>Back to homepage</Button>
              </Link>
            </Content>
          </ContentBox>
        </StyledAbout>
      </Container>
    </>
  );
};

export { OrderSuccess };
