import styled from "styled-components";

export const StyledAbout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
`;

export const ContentBox = styled.div`
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 800px;
`;

export const Heading = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  text-align: center;
`;

export const Content = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  padding-bottom: 2rem;
  padding: 1rem;
  position: relative;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme["@primary-color"]};
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.8rem 2rem;
  border-radius: 0.8rem;
  text-transform: uppercase;
  transition: background-color 0.3s ease 0s;
  width: 70%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -52%);
  font-weight: 500;

  &:hover:not(&:hover) {
    background-color: #40a9ff;
  }

  &:disabled {
    background-color: ${({ theme }) => theme["@primary-light"]};
    cursor: not-allowed;
  }
`;
