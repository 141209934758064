import styled from "styled-components";
import { Button as AntButton, Input } from "antd";

export const StyledHome = styled.div`
  // Your existing styles

  h3 {
    font-weight: 500;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Button = styled(AntButton)`
  background-color: ${({ theme }) => theme["@primary-color"]};
  border: none;
  color: white;
  cursor: pointer;
  /* padding: 0.8rem 1rem; */
  border-radius: 4px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  width: 150px;

  &:hover, &:active {
    /* background-color: ${({ theme }) => theme["@primary-color"]}; */
    color: white;
  }

  &:where(.css-dev-only-do-not-override-ph9edi).ant-btn-default:not(:disabled):hover {
    color: white;
    border-color: white;
  }
  &:disabled {
    background-color: ${({ theme }) => theme["@primary-light"]};
    cursor: not-allowed;
  }
`;

export const StyledInputSearch = styled(Input)`
  /* margin: 16px 0; */
  border: none;
  height: 40px;
  font-size: 0.875rem;
  background-color: #f2f2f2 !important;

  input {
    border-color: #f2f2f2 !important;
    background-color: #f2f2f2 !important;
  }

  .ant-input-search-icon {
    left: 8px;
  }

  .ant-input {
    padding-left: 32px;
  }
`;
