import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetOrder, OrderDeatil, TokenObj, User } from "./Auth.interface";

const initialState: User = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state = {};
      return initialState;
    },
    setToken: (state, { payload }: PayloadAction<TokenObj>) => {
      state.token = payload.token;;
      localStorage.setItem("token", payload.token);
    },
    setTokenExpiresIn: (state, { payload }: PayloadAction<TokenObj>) => {
      state.tokenExpiresIn = payload.tokenExpiresIn
    },
    setUser: (state, { payload }: PayloadAction<User["user"]>) => {
      state.user = payload;
    },
    setOrder: (state, { payload }: PayloadAction<GetOrder>) => {
      state.getOrders = payload;
    },
    setSelectedOrder: (state, { payload }: PayloadAction<OrderDeatil>) => {
      state.order = payload;
    },
  },
});

export const { reset, setToken, setUser, setOrder, setSelectedOrder,setTokenExpiresIn } = authSlice.actions;
export default authSlice.reducer;
