import { Image, Select, Typography } from "antd";
import React from "react";
import BraintreePaymentForm from "../../components/BraintreePaymentForm/BraintreePaymentForm";
import { Container } from "../../components/Container/Container";
import { Header } from "../../components/Header/Header";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { Product } from "../../services/Product";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  CartItem,
  setCart
} from "../../store/slice";
import emptyCartImg from "./../../assets/imgs/empty-cart 1.png";
import {
  AdditionalNoteInput,
  CartItemList,
  CartWrapper,
  CartWrapperEmpty,
  OrderSummary,
  OrderSummaryItem
} from "./Cart.styled";

const Cart: React.FC = () => {
  const cart = useAppSelector((state) => state.global.cart);
  const dispatch = useAppDispatch();
  
  const calculateSubTotal = React.useMemo((): number => {
    let subTotal = 0;
    cart?.items.forEach((item: CartItem) => {
      subTotal += +item.product.price * item.quantity;
    });

    return parseFloat(subTotal.toFixed(2));
  }, [cart.items]);




  if (cart.items.length === 0) {
    return (
      <>
        <Header heading="My Basket" />
        <Container>
          <CartWrapperEmpty>
            <div>
              <Image
                src={emptyCartImg}
                alt="Company Logo"
                width={300}
                preview={false}
                height="auto"
                style={{ marginTop: "2rem" }}
              />
              <Typography.Title level={4}>Cart is Empty</Typography.Title>
            </div>
          </CartWrapperEmpty>
        </Container>
      </>
    );
  }
  return (
    <>
      <Header heading="My Basket" />
      <Container>
        <CartWrapper>
          <CartItemList>
            <ProductsList
              products={
                cart.items.map((item: CartItem) => ({
                  ...item.product,
                  quantity: item.quantity,
                })) as Product[]
              }
              showCross={true}
            />
          </CartItemList>
          <OrderSummary>
            <OrderSummaryItem>
              <span>Items:</span> <span>{cart.items.length}</span>
            </OrderSummaryItem>
            <OrderSummaryItem>
              <span>Delivery Type:</span>{" "}
              <span>
                <Select
                  defaultValue={cart.type}
                  onChange={(val) => dispatch(setCart({ ...cart, type: val }))}
                >
                  <Select.Option value="delivery">Delivery</Select.Option>
                  <Select.Option value="collection">Collection</Select.Option>
                </Select>
              </span>
            </OrderSummaryItem>

            <OrderSummaryItem>
              <p style={{ marginBottom: 0 }}>Additional Note:</p>
              <AdditionalNoteInput
                id="additionalNote"
                value={cart.additionalNote}
                onChange={(e) =>
                  dispatch(setCart({ ...cart, additionalNote: e.target.value }))
                }
              />
            </OrderSummaryItem>
   
            <OrderSummaryItem>
              <span>Sub Total:</span> <strong>{calculateSubTotal}</strong>
            </OrderSummaryItem>
            <BraintreePaymentForm calculateSubTotal={calculateSubTotal} />
          </OrderSummary>
        </CartWrapper>
      </Container>
    </>
  );
};

export { Cart };
