import styled from "styled-components";
import { getStyledContainerWidth } from "../../utils/utils";

export const MT = styled.div`
  margin-top: 80px;
`;

export const StyledFooter = styled.footer`
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  width: ${({ theme }) => getStyledContainerWidth(theme.screen)};

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 60px;
    background-color: white;
    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    border-radius: 10px 10px 0px 0px;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
    height: 100%;
    width: 100%;
    transition: all 0.2s ease-in-out;
  }

  a:hover {
    color: ${({ theme }) => theme["@primary-color"]};
  }

  .active {
    color: ${({ theme }) => theme["@primary-color"]};
  }

  .head {
    margin-top: 0.15rem;
    font-size: 0.8rem;
  }
`;
