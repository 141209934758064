import { Router } from "./router/router";
import toast, { Toaster, ToastOptions } from "react-hot-toast";
import { ThemeProvider } from "styled-components";
import useBreakpoint from "./hooks/useBreakpoints";
import GlobalStyle from "./theme/globalStyle";
import theme from "./theme/theme";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { useRefreshTokenMutation } from "./services/Auth/auth";
import { useAppDispatch } from "./store/hooks";
import { reset, setToken, setTokenExpiresIn } from "./store/slice";

function App() {
  const screen = useBreakpoint();
  const tokenExpiresIn = useSelector((state: RootState) => state.auth.tokenExpiresIn)
  const dispatch = useAppDispatch()
  const  [ refreshToken ] = useRefreshTokenMutation()
  useEffect(() => {
    const millisecondInHour = 0.1 * 60 * 1000
    if (tokenExpiresIn && tokenExpiresIn <= Date.now() + millisecondInHour) {
      refreshToken(null).unwrap()
        .then((res) => {
          dispatch(setToken(res))
          dispatch(setTokenExpiresIn(res))
        }).catch((err) => {
          console.error('error', err)
          dispatch(reset())
          toast.error('You need to login again ')
         })
    }
  }, [tokenExpiresIn])
  const toastOption: ToastOptions = {
    position: "top-center",
    duration: 2000,
  };

  return (
    <ThemeProvider theme={{ ...theme, screen }}>
      <GlobalStyle />
      <Toaster {...toastOption} />
      <Router />
    </ThemeProvider>
  );
}
export default App;
