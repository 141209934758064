import {
  CloseOutlined,
  ShoppingCartOutlined
} from "@ant-design/icons";
import { Image, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Product, ProductQuantities } from "../../services/Product";
import { ProductsTableProps } from "./ProductsTable.interface";
import {
  ProductInfo,
  QuantityButton,
  QuantityInput,
  ResponsiveRow,
  RowDivider,
  StyledTable,
} from "./ProductsTable.styled";

const getColumns = (
  handleQuantityChange: (record: Product, value: string) => void,
  incrementQuantity: (record: Product) => void,
  decrementQuantity: (record: Product) => void,
  handleAddToCart: (record: Product) => void,
  removeFromCart: (record: Product) => void
): ColumnProps<Product>[] => [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    width: 85,
    render: (text: string, record: Product) => (
      <Image width={85} src={record.image} alt={record.name} />
    ),
  },
  {
    title: "Product",
    dataIndex: "name",
    key: "name",
    render: (text: string, record: Product) => (
      <ResponsiveRow>
        <ProductInfo>
          {record.showCross ? (
            <CloseOutlined
              style={{
                position: "absolute",
                top: -10,
                right: -10,
                fontWeight: "bold",
                color: "gray",
              }}
              onClick={() => removeFromCart(record)}
            />
          ) : null}
          <p className="name">{record.name}</p>
          <p className="desc">{record.description}</p>
        </ProductInfo>
        <RowDivider>
          <div className="price">£{record.price}</div>
          <div className="quantity">
            <div className="ctrls">
              <QuantityButton onClick={() => decrementQuantity(record)}>
                -
              </QuantityButton>
              <QuantityInput
                type="number"
                value={record.quantity}
                disabled={record.showCross}
                onChange={(e) => handleQuantityChange(record, e.target.value)}
              />
              <QuantityButton onClick={() => incrementQuantity(record)}>
                +
              </QuantityButton>
            </div>

            {!record.showCross ? (
              <div className="cart-btn" onClick={() => handleAddToCart(record)}>
                <ShoppingCartOutlined
                  style={{
                    color: "white",
                    fontSize: 12,
                    marginRight: "4px",
                  }}
                />
                <span>ADD</span>
              </div>
            ) : null}
          </div>
        </RowDivider>
      </ResponsiveRow>
    ),
  },
];

export const ProductsTable: React.FC<ProductsTableProps> = ({
  products,
  addItemToCart,
  removeItemToCart,
  incQuantity = () => {},
  decQuantity = () => {},
  showCross = false,
}) => {
  const [productQuantities, setProductQuantities] = useState<ProductQuantities>(
    () => {
      const hashMap = {} as ProductQuantities;
      products?.map((product) => {
        hashMap[product._id] = 0;
      });
      return hashMap;
    }
  );

  const handleQuantityChange = (product: Product, value: string) => {
    const productId = product._id;
    console.log({ productQuantities, value });
    if (value === "") {
      setProductQuantities((prevState: any) => ({
        ...prevState,
        [productId]: null,
      }));
    } else {
      setProductQuantities((prevState: any) => ({
        ...prevState,
        [productId]: +value,
      }));
    }
  };

  const incrementQuantity = (product: Product) => {
    if (showCross) {
      incQuantity(product);
    } else {
      const productId = product._id;
      setProductQuantities((prevState: any) => ({
        ...prevState,
        [productId]: (prevState[productId] || 0) + 1,
      }));
    }
  };

  const decrementQuantity = (product: Product) => {
    if (showCross && product.quantity && product.quantity > 0) {
      const quantity = productQuantities[product._id] || product.quantity || 1;
      if (quantity > 1) {
        decQuantity(product);
      }
    } else {
      const productId = product._id;
      setProductQuantities((prevState: any) => ({
        ...prevState,
        [productId]: prevState[productId] > 0 ? prevState[productId] - 1 : 0,
      }));
    }
  };

  const handleAddToCart = (product: Product) => {
    const quantity = productQuantities[product._id];
    if (quantity && quantity > 0) {
      addItemToCart({ ...product, quantity });
    } else {
      toast.error("Quantity must be granter than 0");
    }
  };

  const removeFromCart = (product: Product) => {
    removeItemToCart(product);
  };

  const getQuantity = (product: Product) =>
    showCross ? product.quantity : productQuantities[product._id];

  const dataSource = products?.map((product) => {
    return {
      ...product,
      quantity: getQuantity(product),
      showCross,
    };
  });

  return (
    <StyledTable>
      <Table
        className="antd-table"
        dataSource={dataSource}
        columns={getColumns(
          handleQuantityChange,
          incrementQuantity,
          decrementQuantity,
          handleAddToCart,
          removeFromCart
        )}
        rowKey={(record) => record._id}
        pagination={false}
        showHeader={false}
      />
    </StyledTable>
  );
};
