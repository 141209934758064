import { BrowserRouter, Routes } from "react-router-dom";

import { Layout } from "./../layouts/Layout";
import { RecursiveRouteMapper } from "./RecursiveRouteMapper";
import {
  OPEN_ROUTES,
} from "./routes";

export const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        {() => (
          <Routes>

            {RecursiveRouteMapper(OPEN_ROUTES)}
          </Routes>
        )}
      </Layout>
    </BrowserRouter>
  );
};
