import React from "react";
import { Divider, Image, Skeleton, Typography } from "antd";
import { OrderDetails, OrderItem, OrderList } from "./ViewOrders.styled";
import { useViewOrdersQuery } from "../../services/Auth/auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setOrder } from "../../store/slice";
import { Container } from "../../components/Container/Container";
import { Header } from "../../components/Header/Header";
import { CartWrapperEmpty } from "../Cart/Cart.styled";
import emptyCartImg from "./../../assets/imgs/empty-cart 1.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useAuthRedirect from "../../hooks/useAuthRedirect";

export const ViewOrders = () => {
  const { data: orderData, isLoading } = useViewOrdersQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orders = useAppSelector((state) => state.auth.getOrders);

  useAuthRedirect();

  React.useEffect(() => {
    dispatch(setOrder(orderData));
  }, [orderData,dispatch]);

  return (
    <>
      <Header heading="Past Orders" />
      <Container>
        {isLoading ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <OrderList>
            {orders?.orders.length ? (
              orders?.orders?.map((order) => (
                <OrderItem
                  key={order._id}
                  onClick={() => navigate(`/order/${order._id}`)}
                >
                  <OrderDetails>
                    <Typography.Title level={5}>
                      Past Order Summary
                    </Typography.Title>
                  </OrderDetails>
                  <OrderDetails>
                    <span>Order Number</span> <span>{order._id}</span>
                  </OrderDetails>
                  <OrderDetails>
                    <span>Date</span>{" "}
                    <span>
                      {moment(
                        new Date(parseInt(order?.created_at) * 1).toUTCString()
                      ).format("DD, MMM YYYY")}
                    </span>
                  </OrderDetails>
                  <OrderDetails>
                    <span>Total Length</span> <span>{order.itemsLength}</span>
                  </OrderDetails>
                  {order.additionalNote ? (
                    <OrderDetails>
                      <span>Additional Note</span>{" "}
                      <span>
                        {order.additionalNote ? order.additionalNote : ""}
                      </span>
                    </OrderDetails>
                  ) : null}
                  <OrderDetails>
                    <span>Type</span> <span>{order.type}</span>
                  </OrderDetails>
                  <Divider />
                  <OrderDetails>
                    <span>Subtotal</span>{" "}
                    <span>
                      <strong>£{order.subTotal}</strong>
                    </span>
                  </OrderDetails>
                </OrderItem>
              ))
            ) : (
              <>
                <Container>
                  <CartWrapperEmpty>
                    <Image
                      src={emptyCartImg}
                      alt="Company Logo"
                      width={300}
                      preview={false}
                      height="auto"
                      style={{ marginTop: "2rem" }}
                    />
                    <Typography.Title level={4}>
                      There is no past Orders
                    </Typography.Title>
                  </CartWrapperEmpty>
                </Container>
              </>
            )}
          </OrderList>
        )}
      </Container>
    </>
  );
};
