import { Card, Col, Image } from "antd";
import React from "react";
import { StyledAddButton, StyledCard, StyledRow } from "./ProductsList.styled";

import { ShoppingOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import { Product } from "../../services/Product";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CartItem, ViewType, setCart } from "../../store/slice";
import { ProductsTable } from "../ProductsTable.tsx/ProductsTable";
import { ProductsListProps } from "./ProductsList.interface";

export const ProductsList: React.FC<ProductsListProps> = ({
  products,
  showCross = false,
}) => {
  const cart = useAppSelector((state) => state.global.cart);
  const user = useAppSelector((state) => state.auth.user);
  const viewType = useAppSelector((state) => state.global.viewType);
  const dispatch = useAppDispatch();

  const addItemToCart = (prod: Product) => {
    let isUpdated = false;
    const newCart = structuredClone(cart);
    let cartItems = newCart?.items?.map((e: CartItem) => {
      if (e.product._id === prod._id) {
        e.quantity += 1;
        isUpdated = true;
      }
      return e;
    });
    if (isUpdated) {
      newCart.items = cartItems;
      toast.success("cart Item updated");
    } else {
      toast.success("Item added to cart");
      newCart.items.push({
        quantity: prod.quantity ? prod.quantity : 1,
        product: prod,
      });
    }
    dispatch(setCart(newCart));
  };

  const incCart = (prod: Product) => {
    const newCart = structuredClone(cart);
    newCart?.items?.map((e: CartItem) => {
      if (e.product._id === prod._id) {
        e.quantity += 1;
      }
      return e;
    });
    dispatch(setCart(newCart));
  };

  const decCart = (prod: Product) => {
    const newCart = structuredClone(cart);
    newCart?.items?.map((e: CartItem) => {
      if (e.product._id === prod._id && e.quantity > 0) {
        e.quantity -= 1;
      }
      return e;
    });
    dispatch(setCart(newCart));
  };

  const removeItemToCart = (prod: Product) => {
    const newCart = structuredClone(cart);
    newCart.items = newCart.items.filter(
      (e: CartItem) => e.product._id !== prod._id
    );
    dispatch(setCart(newCart));
  };

  return (
    <>
      {viewType === ViewType.sm ? (
        <ProductsTable
          products={products}
          addItemToCart={addItemToCart}
          removeItemToCart={removeItemToCart}
          showCross={showCross}
          decQuantity={decCart}
          incQuantity={incCart}
        />
      ) : (
        <StyledRow gutter={[32, 32]}>
          {products?.map((product) => (
            <Col
              key={product._id}
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <StyledCard
                cover={
                  <Image
                    src={product.image}
                    alt={product.name}
                    height={280}
                    style={{ objectFit: "scale-down" }}
                  />
                }
              >
                {!user?.isAdmin ? (
                  <StyledAddButton
                    type="primary"
                    onClick={() => addItemToCart(product)}
                  >
                    <ShoppingOutlined /> Add
                  </StyledAddButton>
                ) : null}
                <Card.Meta
                  title={product.name}
                  description={
                    <>
                      <div>{product.feature_text}</div>
                      <div className="prod-price">£{product.price}</div>
                    </>
                  }
                />
              </StyledCard>
            </Col>
          ))}
        </StyledRow>
      )}
    </>
  );
};
