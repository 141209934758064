import React, { useRef, useState } from "react";
import { Category } from "../../services/Category";
import {
  CategoryPill,
  CategoryPillContainer,
  ContainerCategoryList,
  StyledLeftOutlined,
  StyledRightOutlined,
} from "./CategoriesList.styled";
import { CategoriesListProps } from "./CategoriesList.interface";

export const CategoriesList: React.FC<CategoriesListProps> = ({
  categories,
  onCategoryClick,
  selectedCategoryId,
}) => {
  const categoryPillContainerRef = useRef<HTMLDivElement | null>(null);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  const handleRightClick = () => {
    if (categoryPillContainerRef.current) {
      const newScrollPosition =
        categoryPillContainerRef.current.scrollLeft + 200;
      categoryPillContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });

      if (newScrollPosition > 0) {
        setShowLeftArrow(true);
      }

      const maxScrollPosition =
        categoryPillContainerRef.current.scrollWidth -
        categoryPillContainerRef.current.clientWidth;
      if (newScrollPosition >= maxScrollPosition) {
        setShowRightArrow(false);
      }
    }
  };

  const handleLeftClick = () => {
    if (categoryPillContainerRef.current) {
      const newScrollPosition =
        categoryPillContainerRef.current.scrollLeft - 100;
      categoryPillContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });

      if (newScrollPosition <= 0) {
        setShowLeftArrow(false);
      }
      if (
        newScrollPosition <
        categoryPillContainerRef.current.scrollWidth -
          categoryPillContainerRef.current.clientWidth
      ) {
        setShowRightArrow(true);
      }
    }
  };

  return (
    <ContainerCategoryList>
      <CategoryPillContainer ref={categoryPillContainerRef}>
        <CategoryPill
          onClick={() => onCategoryClick("0")}
          selected={selectedCategoryId === "0"}
        >
          All Categories
        </CategoryPill>
        {categories?.map((category: Category) => (
          <CategoryPill
            key={category._id}
            onClick={() => onCategoryClick(category._id)}
            selected={category._id === selectedCategoryId}
          >
            {category.name}
          </CategoryPill>
        ))}
      </CategoryPillContainer>
      {showRightArrow && <StyledRightOutlined onClick={handleRightClick} />}
      {showLeftArrow && <StyledLeftOutlined onClick={handleLeftClick} />}
    </ContainerCategoryList>
  );
};
