import styled from "styled-components";
import { Button as AntdButton } from "antd";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

export const CartIcon = styled.div``;

export const ShoppingDot = styled.div`
  position: absolute;
  top: 40%;
  right: -12px;
  border-radius: 50%;
  background: rgb(14, 74, 124, 0.9);
  font-size: 12px;
  color: white;
  width: 18px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Button = styled(AntdButton)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: inherit;
  padding: 0;
  position: relative;

  &:hover {
    color: #1890ff;
  }
`;

export const MobileMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  /* @media (min-width: 769px) {
    display: none;
  } */
`;

export const DesktopMenu = styled.div`
  /* @media (max-width: 768px) { */
  display: none;
  /* } */
`;

export const PlainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.04em;
  }
`;
