import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled from "styled-components";

export const ContainerCategoryList = styled.div`
  position: relative;
`;

export const CategoryPillContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  gap: 0.5rem;
  margin-top: 0.5rem;

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* border-radius: 10px; */
  }
`;

export const CategoryPill = styled(Button)<{ selected: boolean }>`
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0.4rem;
  white-space: nowrap;
  transition: 0.2s ease-in-out;

  background-color: ${({ theme, selected }) =>
    selected ? theme["@primary-color"] : "#C2BEBE"};
  color: white;
  box-shadow: none;
  outline: none;

  &:active,
  &:focus,
  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme["@primary-color"] : "#C2BEBE"};
    color: white !important;
    box-shadow: none !important;
    outline: none !important;
  }
`;

export const StyledLeftOutlined = styled(LeftOutlined)`
  position: absolute;
  left: 0;
  top: 8%;
  color: darkgray;
  cursor: pointer;
  background-color: #36454f;
  border-radius: 50%;
  padding: 4px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledRightOutlined = styled(RightOutlined)`
  position: absolute;
  right: 0;
  top: 8%;
  color: darkgray;
  cursor: pointer;
  background-color: #36454f;
  border-radius: 50%;
  padding: 4px;

  @media (max-width: 768px) {
    display: none;
  }
`;
