import { Image, Modal } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { paths } from "../../constants/paths";
import useAuthRedirect from "../../hooks/useAuthRedirect";
import {
  useGetUserQuery,
  useLogoutMutation,
  useUpadteUserMutation,
  useUpadteUserPasswordMutation,
} from "../../services/Auth/auth";
import { RootState } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { reset, setUser } from "../../store/slice";
import userLogo from "./../../assets/imgs/logoMain.png";
import { Input } from "./../../components/Input/Input";
import {
  MyProfileButton,
  MyProfileForm,
  MyProfileWrapper,
} from "./MyProfile.styled";

const MyProfile: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);

  useAuthRedirect();


  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const [logout] = useLogoutMutation()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateUser, { isLoading: isLoadinguser }] = useUpadteUserMutation();
  const [upadteUserPassword, { isLoading: isLoadingPassword }] =
    useUpadteUserPasswordMutation();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("address", user.address);
      setValue("contact", user.contact);
    }
  }, [user, setValue]);

  const { refetch } = useGetUserQuery(null);

  const onSubmit = async (data: any) => {
    toast.promise(
      updateUser({
        name: data.name,
        address: data.address,
        contact: data.contact,
      }).unwrap(),
      {
        error: (e) =>
          e
            ? Array.isArray(e)
              ? e[0].message
              : e.message
            : "Something went wrong",
        loading: "loading",
        success: (response) => {
          (async () => {
            const updatedUser = await refetch();
            if (updatedUser.data?.user) {
              dispatch(setUser(updatedUser.data.user));
            }
          })();
          return "User data updated";
        },
      }
    );
  };

  const onSubmitPasswrdForm = async (data: any) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Password not matched");
      return;
    }

    toast.promise(upadteUserPassword({ password: data.password }).unwrap(), {
      error: (e) =>
        e
          ? Array.isArray(e)
            ? e[0].message
            : e.message
          : "Something went wrong",
      loading: "loading",
      success: () => {
        setValue("password", "");
        setValue("confirmPassword", "");
        setIsModalVisible(false);
        return "User password changed";
      },
    });
  };

  const handleCancel = () => setIsModalVisible(false);

  const inputProps = [
    {
      name: "name",
      type: "text",
      control: control,
      rules: { required: "Name is required" },
      placeholder: "Name",
    },
    {
      name: "email",
      type: "email",
      control: control,
      rules: {},
      placeholder: "Email",
      disabled: true,
    },
    {
      name: "address",
      type: "text",
      control: control,
      rules: { required: "Address is required" },
      placeholder: "Address",
    },
    {
      name: "contact",
      type: "text",
      control: control,
      rules: { required: "Contact number is required" },
      placeholder: "Contact",
    },
  ];

  const inputPropsPasswordForm = [
    {
      name: "password",
      type: "password",
      control: control,
      rules: { required: "Password is required" },
      placeholder: "Password",
    },
    {
      name: "confirmPassword",
      type: "password",
      control: control,
      rules: { required: "Password is required" },
      placeholder: "Confirm Password",
    },
  ];

  const handleLogout = () => {
    toast.promise(logout(null).unwrap(), {
      error: (e) => 'Something went wrong',
      loading: "Loading...",
      success: () => {
        dispatch(reset())
        navigate(paths.HOME)
        return 'Logged out successfully'
      }
    })
  };

  return (
    <>
      <Header heading={"Profile"} />
      <MyProfileWrapper>
        <Image
          src={userLogo}
          width={220}
          preview={false}
          style={{ marginBottom: 50 }}
        />
        <MyProfileForm onSubmit={handleSubmit(onSubmit)}>
          {inputProps?.map((props) => (
            <Input key={props.name} {...props} />
          ))}
          <MyProfileButton type="submit" disabled={isLoadinguser}>
            Save Changes
          </MyProfileButton>
          <MyProfileButton onClick={() => setIsModalVisible(true)}>
            Change Password
          </MyProfileButton>
        </MyProfileForm>

        <MyProfileButton style={{marginTop: '10px'} } onClick={handleLogout}>Logout</MyProfileButton>

        <Modal
          title="Change Password"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <MyProfileForm
            onSubmit={handleSubmit(onSubmitPasswrdForm)}
            style={{ margin: "auto" }}
          >
            {inputPropsPasswordForm?.map((props) => (
              <Input key={props.name} {...props} />
            ))}
            <MyProfileButton type="submit" disabled={isLoadingPassword}>
              Change Password
            </MyProfileButton>
          </MyProfileForm>
        </Modal>
      </MyProfileWrapper>
    </>
  );
};

export { MyProfile };
