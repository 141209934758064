export enum paths {
  HOME = "/",
  LOGIN = "/login",
  SIGNUP = "/signup",
  ABOUT = "/about",
  PROFILE = "/profile",
  CART = "/cart",
  ORDER_SUCCESS = "/success",
  ORDER_VIEW = "/orders",
  ORDER_VIEWW = "/order/:id",
}
