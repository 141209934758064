import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useRegisterMutation } from '../services/Auth/auth';
import { setToken, setTokenExpiresIn, setUser } from '../store/slice';

function useSignupform() {
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const [register, { isLoading, error }] = useRegisterMutation();
    const onSubmit = async (data: any) => {
        try {
            const newData = { ...data };
            if (newData.password !== newData.confirmPassword) {
                toast.error("Password not matched");
                return;
            }
            delete newData.confirmPassword;

            toast.promise(register(newData).unwrap(), {
                error: (e) =>
                    e
                        ? Array.isArray(e)
                            ? e[0].message
                            : e.message
                        : "Something went wrong",
                loading: "loading",
                success: (response: any) => {
                    dispatch(setUser(response.user));
                    dispatch(setToken(response.tokenObj));
                    dispatch(setTokenExpiresIn(response.tokenObj))
                    return "user signup Successful";
                },
            });
        } catch (err) {
            console.error("Signup failed:", err);
        }
    };
    return {
        onSubmit: handleSubmit(onSubmit),
        control, isLoading, error
    }
}

export default useSignupform