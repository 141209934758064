import styled from "styled-components";

export const PlaceOrderButton = styled.button`
  background-color: ${({ theme }) => theme["@primary-color"]};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover:not(&:hover) {
    background-color: #40a9ff;
  }
  &:disabled {
    background-color: ${({ theme }) => theme["@primary-light"]};
    cursor: not-allowed;
  }
`;

export const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const CartWrapperEmpty = styled.div`
  text-align: center;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  h4 {
    color: #e9e9e9;
  }
`;

export const CartItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CartItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CartItemInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme["@primary-color"]};
  border-radius: 2rem;
  padding: 0.3rem;

  button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  span {
    color: white;
    font-size: 1.2rem;
  }
`;

export const OrderSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const OrderSummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const AdditionalNoteInput = styled.textarea`
  resize: none;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
`;
