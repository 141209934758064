import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  span, div {
    font-family: 'Roboto', sans-serif;
  }
  .font-bold {
    font-weight: bold;
  }
  .font-semibold {
    font-weight: 700;
  }
  .ant-divider:where(.css-dev-only-do-not-override-ph9edi).ant-divider-horizontal {
    margin: 12px 0;
  }
`;

export default GlobalStyle;
