import React from 'react'
import { ImageCenter, LoginButton, LoginForm, LoginWrapper } from '../../pages/Signup/Signup.styled'
import { Image } from 'antd'
import LogoImg from '../../assets/imgs/logo.png'
import { Input } from '../Input/Input';
import { SignupFormProps } from './SignupForm.interface';



function SignupForm({ onSubmit, control, isLoading, children }: SignupFormProps) {
    const inputProps = [
        {
            name: "name",
            type: "text",
            control: control,
            rules: { required: "Name is required" },
            placeholder: "Name",
        },
        {
            name: "email",
            type: "email",
            control: control,
            rules: { required: "Email is required" },
            placeholder: "Email",
        },
        {
            name: "address",
            type: "text",
            control: control,
            rules: { required: "Address is required" },
            placeholder: "Address",
        },
        {
            name: "contact",
            type: "text",
            control: control,
            rules: { required: "Contact is required" },
            placeholder: "Contact",
        },
        {
            name: "password",
            type: "password",
            control: control,
            rules: { required: "Password is required" },
            placeholder: "Password",
        },
        {
            name: "confirmPassword",
            type: "password",
            control: control,
            rules: { required: "Password is required" },
            placeholder: "Confirm Password",
        },
    ];
    return (
        <LoginForm onSubmit={onSubmit}>
            <ImageCenter>
                <Image
                    src={LogoImg}
                    alt="Company Logo"
                    width={180}
                    preview={false}
                    height="auto"
                    style={{ marginBottom: "2rem" }}
                />
            </ImageCenter>
            {inputProps?.map((props) => (
                <Input key={props.name} {...props} />
            ))}
            <LoginButton type="submit" disabled={isLoading}>
                Signup
            </LoginButton>
            {children}
        </LoginForm>

    )
}

export default SignupForm