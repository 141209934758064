import styled from "styled-components";

export const OrderList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const OrderItem = styled.li`
  background-color: #fff;
  border-radius: 6px;
  font-size: 0.875rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1rem;
`;

export const OrderDetails = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  h5 {
    margin-top: 0;
  }
`;
