import { Divider } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import SignupForm from "../../components/SignupForm/SignupForm";
import { paths } from "../../constants/paths";
import useSignupform from "../../hooks/useSignupform";
import {
  BottomLink,
  BottomText,
  LoginWrapper
} from "./Signup.styled";

const Signup: React.FC = () => {


  const navigate = useNavigate();
  const { control, error, isLoading, onSubmit } = useSignupform()

  const onSubmitSignupForm = async (data: any) => {
    await onSubmit(data)
    if (!error) {
      navigate(paths.HOME)
    }
  }



  return (
    <LoginWrapper>
      <SignupForm control={control} isLoading={isLoading} onSubmit={onSubmitSignupForm} children={<>
        <Divider plain>OR</Divider>
        <BottomText>
          Already have an account?
          <BottomLink onClick={() => navigate(paths.LOGIN)}> login</BottomLink>.
        </BottomText>
      </>} />

    </LoginWrapper>
  );
};

export { Signup };
