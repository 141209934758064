import styled from "styled-components";

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 1rem;
`;

export const ImageCenter = styled.div`
  text-align: center;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
`;

export const LoginButton = styled.button`
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  transition: 0.3s ease;
  width: 100%;
  background-color: ${({ theme }) => theme["@primary-color"]};

  &:hover:not(&:hover) {
    background-color: #40a9ff;
  }
  &:disabled {
    background-color: ${({ theme }) => theme["@primary-light"]};
    cursor: not-allowed;
  }
`;

export const BottomText = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0;
`;

export const BottomLink = styled.a`
  color: #1890ff;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
