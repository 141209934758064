import { Dropin } from 'braintree-web-drop-in';
import React, { memo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/paths';
import { PlaceOrderButton } from '../../pages/Cart/Cart.styled';
import { useAddOrderMutation, useViewOrdersQuery } from '../../services/Auth/auth';
import { ClientPaymentToken, PaymentData, getClientBrainTreeToken, makePayment } from '../../services/Payment';
import { useAppSelector } from '../../store/hooks';
import { CartItem, initCart, setCart } from '../../store/slice';
import { BraintreePaymentFormProps } from './BraintreePaymentForm.interfaces';

const BraintreePaymentForm: React.FC<BraintreePaymentFormProps> = ({ calculateSubTotal }) => {
    const user = useAppSelector((state) => state.auth.user);
    const cart = useAppSelector((state) => state.global.cart);
    const [brainTreeClientToken, setBrainTreeClientToken] = React.useState<string | null>(null)
    const [braintreeInstance, setBraintreeInstance] = React.useState<Dropin | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [addOrder] = useAddOrderMutation();
    const navigate = useNavigate();
    const { refetch } = useViewOrdersQuery();
    const dispatch = useDispatch()

    React.useEffect(() => {
        getClientBrainTreeToken().then((tokenObject: ClientPaymentToken | undefined) => {
            const token = tokenObject?.clientToken
            if (token) {
                setBrainTreeClientToken(token)
            }

        })
    }, [])

    const handlePlaceOrder = async () => {
        const newStateCart: any = { ...cart, subTotal: calculateSubTotal };
        newStateCart.items = newStateCart.items.map((item: CartItem) => {
            const { product, ...newItem } = item;
            newItem._id = product;
            return newItem;
        });

        setLoading(true);
        toast.promise(addOrder(newStateCart).unwrap(), {
            error: (e) =>
                e
                    ? Array.isArray(e)
                        ? e[0].message
                        : e.message
                    : "Something went wrong",
            loading: "loading",
            success: () => {
                navigate(paths.ORDER_SUCCESS);
                dispatch(setCart(initCart));
                setLoading(false);
                refetch();
                return "Add order Successful";
            },
        });
    };
    return (
        <>
            {/* {brainTreeClientToken && <DropIn options={{
                authorization: brainTreeClientToken,
                googlePay: {
                    googlePayVersion: 2,
                    merchantId: '5303208621',
                    transactionInfo: {
                        totalPriceStatus: 'FINAL',
                        totalPrice: calculateSubTotal.toFixed(2),
                        currencyCode: 'GBP',
                    },
                    button: {
                        buttonSizeMode: 'fill',
                        onClick: handlePlaceOrder
                    }
                }
            }
            }
                onInstance={(inst: Dropin) => setBraintreeInstance(inst)} />} */}
             <PlaceOrderButton onClick={handlePlaceOrder} disabled={loading}>
                Place Order
            </PlaceOrderButton>
        </>
    )
}


export default memo(BraintreePaymentForm) 